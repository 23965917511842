.tableContainer {
  overflow-x: auto;
  margin: 1rem 0;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

table {
  border-collapse: collapse;
  width: 100%;
  min-width: 600px;
  background-color: white;
}

th {
  background-color: #f8f9fa;
  color: #1670d9;
  font-weight: 600;
  text-align: left;
  padding: 12px 16px;
  font-size: 0.9rem;
  border-bottom: 2px solid #e9ecef;
}

td {
  padding: 12px 16px;
  font-size: 0.9rem;
  border-bottom: 1px solid #e9ecef;
  color: #495057;
}

thead {
  font-weight: bold;
}

tr:nth-child(even) {
  background-color: #f8f9fa;
}

tr:hover {
  background-color: #f1f3f5;
}

.tableTitle {
  font-size: 1.1rem;
  font-weight: 600;
  color: #1670d9;
  margin: 0.5rem 0;
  padding: 0 16px;
}
